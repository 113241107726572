import _typeof from "/Users/pitt/study/pmt/pmt.client.fe/node_modules/@babel/runtime/helpers/esm/typeof.js";
/*
* @Author: your name
* @Date: 2019-11-29 15:06:30
* @LastEditTime: 2020-06-08 11:30:21
* @LastEditors: Please set LastEditors
* @Description: In User Settings Edit
* @FilePath: /family.inkept.cn/src/utils/user.js
*/

/* eslint-disable import/no-mutable-exports */

import srcConfig from '../config';
import $cookies from './cookies';
var storageKeys = {
  token: 'TOKEN',
  user: 'USER'
};
storageKeys.ticket = storageKeys.token;
var userInfo = {};
var userResolve;
var userReject;
var userPromise = new Promise(function (resolve, reject) {
  userResolve = resolve;
  userReject = reject;
});
var userReady = userPromise.then.bind(userPromise);
var token = '';
var user = {
  get: function get() {
    return userInfo;
  },
  set: function set(_userInfo) {
    if (_userInfo && _typeof(_userInfo) === 'object') {
      userInfo = _userInfo;
    }
  },
  setToken: function setToken(_token) {
    if (_token) {
      token = _token;
      localStorage.setItem(storageKeys.token, token);
      $cookies.setItem(storageKeys.token, token);
    }
  },
  removeToken: function removeToken() {
    localStorage.removeItem(storageKeys.token);
    $cookies.removeItem(storageKeys.token);
    $cookies.removeItem('ticket');
  },
  getToken: function getToken() {
    return localStorage.getItem(storageKeys.token);
  },
  logout: function logout() {
    user.removeToken();
    setTimeout(function () {
      window.location.href = srcConfig.SSO_LOGOUT_PAGE_SERVICE;
    });
  },
  logoutByOtherMethods: function logoutByOtherMethods() {
    console.log('logout');
  },
  _userPromise: userPromise,
  ready: userReady
};
user.setTicket = user.setToken;
user.getTicket = user.getToken;
user.removeTicket = user.removeToken;
export { userPromise, userReady, userResolve, userReject, storageKeys };
export default user;