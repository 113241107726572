var _ = function _() {
  // const reg = /\?ticket=[A-Za-z0-9]+/
  var reg = /&?(?:ticket)=\w+(&?)/g;
  var href = window.location.href;
  var hrefNoTicket = href.replace(reg, '').replace(/\?$/, '');

  if (reg.test(href)) {
    if (window.history && window.history.replaceState) {
      window.history.replaceState(null, document.title, hrefNoTicket);
    } else {
      window.location.href = hrefNoTicket;
    }
  }
};

export default _;