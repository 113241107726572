import _objectSpread from "/Users/pitt/study/pmt/pmt.client.fe/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import axios from 'axios';
import axiosService from 'axios-service';
import cookie from '../utils/cookies'; // import loading from 'components/loading'
// import $user from 'user'

var setTicketToHeader = function setTicketToHeader(config) {
  var headers = config.headers;
  headers.ticket = cookie.getItem('TOKEN'); // if (window.localStorage.getItem('mail')) {
  //   headers.mail = window.localStorage.getItem('mail')
  //   headers['Auth-Type'] = window.localStorage.getItem('Auth-Type')
  // }
};

var setTicketToParams = function setTicketToParams(config) {
  var _config$params = config.params,
      params = _config$params === void 0 ? {} : _config$params;
  config.params = _objectSpread(_objectSpread({}, params), {}, {
    ticket: cookie.getItem('ticket'),
    account_code: window.localStorage.getItem('account_code')
  });
}; // const TIME_OUT = 5000
// const TIME_OUT = 3e+4;


var TIME_OUT = 10 * 60 * 1000;
axiosService.init(axios, {
  requestDefaults: {
    // 目前还没实现, 预计在下个版本中处理
    autoLoading: true,
    // response.data下面的配置
    // server端请求msg(
    msgKey: 'error_msg',
    // server端数据的key
    dataKey: 'data',
    // server端请求状态的key
    codeKey: 'dm_error',
    // server端请求成功的状态, 注意: 此为response.data下该接口请求成功状态码, 非浏览器中http请求返回的成功状态(200)
    successCode: 0
  }
}); // 超时时间

axios.defaults.timeout = TIME_OUT; // 打开withCredentials

axios.defaults.withCredentials = true; // 请求拦截器

axios.interceptors.request.use(function (config) {
  if (config.autoLoading === undefined || config.autoLoading === true) {// loading.show();
  } // 把 ticket 放入 header 和 query 中，按需选用


  setTicketToHeader(config);
  setTicketToParams(config);
  return config;
}, function (error) {
  // loading.hide();
  console.error('加载超时');
  return Promise.reject(error);
}); // 响应拦截器

axios.interceptors.response.use(function (data) {
  var autoLoading = data.config.autoLoading;

  if (autoLoading === undefined || autoLoading === true) {// loading.hide()
  } // todo 返回 ticket 过期则退出

  /**
     * 处理status为0的情况
     */


  if (Object.prototype.hasOwnProperty.call(data.data, 'status') || Object.prototype.hasOwnProperty.call(data.data, 'msg')) {
    data.data.error_msg = data.data.msg;
    data.data.dm_error = data.data.status;
    delete data.data.status;
    delete data.data.msg;
  } // else if (Object.prototype.hasOwnProperty.call(data.data, 'dm_error')) {
  //   data.data.dm_error = 0
  // }


  return data;
}, function (error) {
  // loading.hide();
  return Promise.reject(error);
});