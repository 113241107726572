var client = {
  loginAccount: function loginAccount(uid, ticket, account_id) {
    window.pywebview.api.callPyApp(uid, "login", 0, String(uid) + "," + String(ticket) + "," + String(account_id));
  },
  createAccount: function createAccount(uid, ticket) {
    window.pywebview.api.callPyApp(uid, "create", 0, String(uid) + "," + String(ticket));
  },
  runMarkTask: function runMarkTask(uid, ticket, account_id, callback) {
    window.pywebview.api.callPyApp(uid, "mark", 1, String(uid) + "," + String(ticket) + "," + String(account_id)).then(function (pid) {
      callback(pid);
    });
  },
  runSpider: function runSpider(uid, ticket, task_id, callback) {
    window.pywebview.api.callPyApp(uid, "spider", 1, String(uid) + "," + String(ticket) + "," + String(task_id)).then(function (pid) {
      callback(pid);
    });
  },
  get_version: function get_version(callback) {
    //alert(window.pywebview)
    if (window.pywebview) {
      window.pywebview.api.getVersion().then(function (ret) {
        callback(ret);
      });
    }
  },
  search_video: function search_video(uid, ticket, param, callback) {
    if (window.pywebview) {
      window.pywebview.api.callPyApp(uid, "search_video", 0, String(uid) + "," + String(ticket) + "," + String(param)).then(function (ret) {
        callback(ret);
      });
    }
  }
};
export default client;